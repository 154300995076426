<template>
  <div class="insert-user-to-access-group">
    <custom-validate-input :label="$t('sales.invoices.labels.user')"
                           :styles="{width: '100%'}"
                           :is-empty="true"
                           align="right"
                           v-model="user.name"/>

    <div class="suggest-modal-button"
         @click="suggestUserPromptStatus = true">
      <custom-icon height="22px" width="22px" icon="LIST" color-code="#197ed5" />
    </div>

    <!-- users List prompt -->
    <vs-prompt
      class="very-big-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="suggestUserPromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="newSuggestUserPromptStatus = true">
              <custom-icon height="22px" width="22px" icon="PLUS" color="success" />
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('sales.invoices.prompts.users.list.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="suggestUserPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <template>
          <keep-alive>
            <select-users :multi-select="false" @selected="handleSuggestUserSelected($event)"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <!-- insert new user prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="newSuggestUserPromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="$u.click('insertUserBTN')">
              <custom-icon icon="CHECK" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('sales.invoices.prompts.users.insert.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="newSuggestUserPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <insert-user :in-modal="true" @inserted="handleNewUserInserted($event)"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <button v-show="false" id="saveBTN" @click="insertUserToAccessGroup"/>
  </div>
</template>

<script>
import CustomValidateInput from '../../../../../components/customInput/customValidateInput'
import CustomIcon from '../../../../../components/customIcon/customIcon'
import InsertUser from '../../../users/insert/insertUser'
import SelectUsers from '../../../users/select/selectUsers'
import {insertUserToRole} from '../../../../../http/requests/roles'
export default {
  name: 'insertUserToAccessGroup',
  components: {SelectUsers, InsertUser, CustomIcon, CustomValidateInput},
  props: {
    roleId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      user: {},
      suggestUserPromptStatus: false,
      newSuggestUserPromptStatus: false
    }
  },
  methods: {
    handleSuggestUserSelected (list) {
      let user = {}
      list.forEach((data) => {
        if (!data.hasOwnProperty('show') || data.show) {
          user = {
            id: data.id,
            name: {
              value: data.name,
              isValid: true
            }
          }
        }
      })
      this.user = user
      this.suggestUserPromptStatus = false
    },
    handleNewUserInserted (user) {
      this.user.id =  user.id
      this.user.name = {
        value: `${user.name  } ${  user.family}`,
        isValid: true
      }
      this.suggestUserPromptStatus = false
      this.newSuggestUserPromptStatus = false
    },
    insertUserToAccessGroup () {
      if (this.user.id > 0) {
        this.$u.loading.show()
        insertUserToRole(this.roleId, {user_id: this.user.id}).then(() => {
          this.$u.loading.hide()
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('setting.accessGroup.insertUserToAccessGroup.notifications.success'),
            color: 'success',
            time: this.$u.getReadingTime(this.$t('setting.accessGroup.insertUserToAccessGroup.notifications.success')),
            icon: 'icon-check',
            iconPack: 'feather'
          })

          this.$emit('insert')
        }).catch(() => {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('setting.accessGroup.insertUserToAccessGroup.notifications.error'),
            color: 'danger',
            time: this.$u.getReadingTime(this.$t('setting.accessGroup.insertUserToAccessGroup.notifications.error')),
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
        })
      } else {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('setting.accessGroup.insertUserToAccessGroup.notifications.noUserSelected'),
          color: 'danger',
          time: this.$u.getReadingTime(this.$t('setting.accessGroup.insertUserToAccessGroup.notifications.noUserSelected')),
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.insert-user-to-access-group {
  position: relative;

  .suggest-modal-button, .new-suggest-modal-button {
    position: absolute;
    bottom: 0;
    right: 5px;
    top: 5px;
    width: 25px !important;
    height: 25px !important;
    padding: 0;
    border: 1px solid #197ed5;
    border-radius: .5rem;
    color: #197ed5;
    background: white;
    cursor: pointer;
  }

  .new-suggest-modal-button {
    right: 35px;
    padding-right: 3px;
    border: 1px solid #28C76F;
    color: #28C76F;
  }
}
</style>
