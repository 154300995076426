<template>
  <div class="insert-access-group">
    <vs-row>
      <vs-col>
        <custom-validate-input :label="$t('setting.accessGroup.insert.labels.name')"
                               :regex="$u.validator('regex.accessGroup.name')"
                               :invalid-text="''"
                               v-model="updateAccessGroup.name"/>

        <custom-number-input :label="$t('setting.accessGroup.insert.labels.allowedActiveSession')"
                             classes="w-full"
                             v-model="updateAccessGroup.activeSessionCount"/>
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col>
        <span class="text-sm useral-text-colors text-blue--300">{{ $t('setting.accessGroup.insert.labels.access') }}</span>
        <span class="text-xs text-danger block w-full"></span>
      </vs-col>

      <custom-tree v-for="(permission, permission_index) in permissions"
                   :key="permission_index"
                   class="w-full"
                   :class="{'mt-2': permission.children.length > 0}"
                   :root="permission.children.length > 0"
                   :config="treeConfig"
                   :node="permission.node"
                   :children="permission.children"
                   v-model="selectedPermissions"/>
    </vs-row>

    <vs-button id="saveBTN" class="useral-action-button" @click="editData"/>
  </div>
</template>

<script>
import CustomSelect from '@/components/customSelect/customSelect'
import CustomValidateInput from '../../../../../components/customInput/customValidateInput'
import CustomNumberInput from '../../../../../components/customInput/customNumberInput'
import CustomTree from '../../../../../components/Tree/CustomTree'
import {editRole, getPermissions, getRolePermissions} from '../../../../../http/requests/roles'

export default {
  name: 'editAccessGroup',
  metaInfo () {
    return {
      title: this.$t('setting.accessGroup.insert.title')
    }
  },
  components: {
    CustomTree,
    CustomNumberInput,
    CustomValidateInput,
    CustomSelect
  },
  props: {
    accessGroup: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      updateAccessGroup: {
        id: null,
        parentId: null,
        name: {
          value: '',
          isValid: true
        },
        activeSessionCount: {
          value: 3,
          isValid: true
        },
        permissions: []
      },
      treeConfig: {
        actions: [],
        collapse: true,
        selectable: true,
        parentSelectable: true
      },
      permissions: [],
      selectedPermissions: [],
      availablePermissions: []
    }
  },
  created () {
    if (this.accessGroup) {
      this.updateAccessGroup.id = this.accessGroup.id
      this.updateAccessGroup.parentId = this.accessGroup.parentId
      this.updateAccessGroup.name.value = this.accessGroup.label
      this.updateAccessGroup.activeSessionCount.value = this.accessGroup.allowedActiveSessions
      this.updateAccessGroup.permissions = this.accessGroup.permissions.map((p) => { return p.id })
    }

    this.getPermissions()
  },
  methods: {
    getPermissions () {
      this.$u.loading.show()

      getRolePermissions(this.updateAccessGroup.parentId).then(response => {
        this.availablePermissions = response.data.data.map((permission) => {
          return permission.id
        })

        getPermissions().then(response => {
          const permissions = response.data.data
          permissions.forEach(permission => {
            this.permissions.push(this.createTree(permission, true))
          })

          this.$u.loading.hide()
        })
      })

    },
    createTree (permission) {
      const children = []
      permission.children.forEach((child) => {
        children.push(this.createTree(child))
      })

      const node = {
        id: permission.id,
        label: permission.children.length > 0 ? `${this.$t(`setting.accessGroup.permissions.${  permission.slug}`)} (${this.$t('setting.accessGroup.insert.labels.inMenu')})` : this.$t(`setting.accessGroup.permissions.${  permission.slug}`),
        show: this.updateAccessGroup.permissions.indexOf(permission.id) > -1
      }
      if (this.availablePermissions.indexOf(permission.id) === -1) node.locked = true
      this.selectedPermissions.push(node)

      return {
        node,
        children
      }
    },
    editData () {
      if (this.updateAccessGroup.name.value === '') {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('setting.accessGroup.edit.validators.nameCantEmpty'),
          color: 'danger',
          time: this.$u.getReadingTime(this.$t('setting.accessGroup.edit.validators.nameCantEmpty')),
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      } else if (!this.updateAccessGroup.name.isValid) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('setting.accessGroup.edit.validators.name'),
          color: 'danger',
          time: this.$u.getReadingTime(this.$t('setting.accessGroup.edit.validators.name')),
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      } else if (this.updateAccessGroup.activeSessionCount.value > 5) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('setting.accessGroup.edit.validators.activeSessionCount'),
          color: 'danger',
          time: this.$u.getReadingTime(this.$t('setting.accessGroup.edit.validators.activeSessionCount')),
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      const role = {
        'id': this.updateAccessGroup.id,
        'name': this.updateAccessGroup.name.value,
        'allowed_active_sessions': this.updateAccessGroup.activeSessionCount.value,
        'permissions': []
      }

      role.permissions = this.selectedPermissions.filter((permission) => {
        return permission.show === true
      }).map((permission) => {
        return permission.id
      })

      if (role.permissions.length === 0) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('setting.accessGroup.edit.validators.permissions'),
          color: 'danger',
          time: this.$u.getReadingTime(this.$t('setting.accessGroup.edit.validators.permissions')),
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      editRole(role).then(() => {
        this.$emit('edit')
        this.$store.dispatch('helper/changeRole')

        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('setting.accessGroup.notifications.edit.success'),
          time: this.$u.getReadingTime(this.$t('setting.accessGroup.notifications.edit.success')),
          color: 'success',
          icon: 'icon-check',
          iconPack: 'feather'
        })
      })
        .catch(() => {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('setting.accessGroup.notifications.edit.error'),
            color: 'danger',
            time: this.$u.getReadingTime(this.$t('setting.accessGroup.notifications.edit.error')),
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
        })
    }
  }
}
</script>

<style scoped>

</style>
