<template>
  <div class="users-opening-balance-box">
    <draggable-dynamic-table ref="usersOpeningBalanceList"
                             :in-modal="true"
                             :fix-screen="true"
                             :active-grid="true"
                             :options="options"
                             :columns="columnsLabel"
                             :suggests="suggests"
                             @row:deleted="handleDeleteRow($event)"
                             @suggest:selected="handleInsertNewRow"
                             @newSuggestPrompt:opened="newSuggestUserPromptStatus = true, rowIndex = $event"
                             @suggestPrompt:opened="suggestPromptStatus = true, rowIndex = $event"
                             v-model="data"/>

    <!-- users list prompt -->
    <vs-prompt
      class="very-big-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="suggestPromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="newSuggestUserPromptStatus = true">
              <custom-icon icon="PLUS" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('users.select.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="suggestPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <template>
          <keep-alive>
            <select-users :opening-balance="type"
                          :multi-select="false"
                          @prompt:reload="handleReloadPrompt()"
                          @selected="handleSuggestSelected($event, rowIndex)"/>
          </keep-alive>
        </template>
        <!--<cash-boxes-opening-balance v-if="header.suggestPromptComponent === 'cashBoxes'"/>-->
      </div>

    </vs-prompt>

    <!-- insert new user prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="newSuggestUserPromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="$u.click('insertUserBTN')">
              <custom-icon icon="CHECK" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('users.insert.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="newSuggestUserPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <insert-user :in-modal="true" @inserted="handleNewUserInserted($event)"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <!-- import user opening balance prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="importDataPromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleSendImportedFile">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('users.openingBalance.labels.importData') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="importDataPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content px-2">
        <template>
          <keep-alive>
            <custom-file-input :label="$t('users.openingBalance.labels.importData')"
                               accept-type=".csv"
                               logo-type="formData"
                               :preview="false"
                               v-model="importedDataFile"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <button v-show="false" id="importDataBTN" @click="importDataPromptStatus = true"/>
    <button id="saveBTN" class="useral-action-button" @click="sendData"></button>
  </div>
</template>

<script>
import axios from 'axios'
import {
  setCreditorUsersOpeningInventories,
  setDebtorUsersOpeningInventories,
  importCreditorUserOpeningInventories,
  importDebtorUserOpeningInventories
} from '@/http/requests/openingInventories'
import {getUsers} from '@/http/requests/users/users'
import SelectUsers from '@/views/admin/users/select/selectUsers'
import InsertUser from '@/views/admin/users/insert/insertUser'
import CustomIcon from '@/components/customIcon/customIcon'
import CustomFileInput from '../../../../components/customInput/customeFileInput.vue'

export default {
  name: 'insertUsersOpeningBalance',
  components: {
    CustomFileInput,
    CustomIcon,
    InsertUser,
    SelectUsers
  },
  data () {
    return {
      newSuggestUserPromptStatus: false,
      suggestPromptStatus: false,
      importDataPromptStatus: false,
      page: 1,
      endedList: false,
      loadingTimer: 0,
      options: {
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'delete',
          i18n: 'users.openingBalance.list.table.header.delete',
          width: '70px',
          minWidth: 70,
          /*sortable: true,*/
          fixed: true,
          actions: true,
          showAction: 'always',
          action: {
            color: 'danger',
            icon: 'TRASH',
            iconPack: 'useral',
            type: 'button'
          },
          event: 'row:deleted'
        },
        {
          field: 'price',
          i18n: 'users.openingBalance.list.table.header.price',
          hideCurrency: true,
          width: '50%',
          minWidth: 200,
          /*sortable: true,*/
          editable: true,
          returnValue: true,
          valueType: 'price',
          align: 'center',
          footer: {
            type: 'auto-sum',
            textType: 'price'
          }
        },
        {
          field: 'name',
          i18n: 'users.openingBalance.list.table.header.name',
          align: 'center',
          width: '50%',
          minWidth: 200,
          /*sortable: true,*/
          editable: true,
          returnValue: false,
          suggest: true
          // suggestPrompt: true
        }
      ],
      importedDataFile: {
        value: '',
        isValid: true
      },
      suggests: {
        name: []
      },
      data: [
        /*{
          id: 0,
          name: '',
          price: '',
          item: 'NEW'
        }*/
      ],
      usersList: []
    }
  },
  created () {
    this.getUsers()
    this.insertNewRow()
    this.getSuggestList()
    this.checkOpeningBalanceStatus()
  },
  props: {
    type: {
      type: String,
      validator (value) {
        return ['creditor', 'debtor'].includes(value)
      }
    }
  },
  methods: {
    sendData () {
      const list = []
      this.data.forEach((item) => {
        if (['UPDATED', 'NEW'].indexOf(item.flag) > -1 && item.id > 0) {
          // console.log(item.flag)
          list.push({
            id: item.id,
            price: item.price.toString().replaceAll(',', '')
          })
        }
      })

      if (this.type === 'debtor') {
        setDebtorUsersOpeningInventories(list).then(() => {
          this.$vs.notify({
            title: this.$t('users.openingBalance.insert.notifications.debtor.success.title'),
            text: this.$t('users.openingBalance.insert.notifications.debtor.success.message'),
            color: 'success',
            time: this.$u.getReadingTime(this.$t('users.openingBalance.insert.notifications.debtor.success.message')),
            icon: 'icon-check',
            iconPack: 'feather'
          })

          this.$store.dispatch('removePageChanges')
          this.$store.dispatch('helper/changeOpeningBalance')
        }).catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: this.$u.getReadingTime(this.$t('alert.duplicateRequest.message')),
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              title: this.$t('users.openingBalance.insert.notifications.debtor.error.title'),
              text: this.$t('users.openingBalance.insert.notifications.debtor.error.message'),
              color: 'danger',
              time: this.$u.getReadingTime(this.$t('users.openingBalance.insert.notifications.debtor.error.message')),
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
          }
        })
      } else {
        setCreditorUsersOpeningInventories(list).then(() => {
          this.$vs.notify({
            title: this.$t('users.openingBalance.insert.notifications.creditor.success.title'),
            text: this.$t('users.openingBalance.insert.notifications.creditor.success.message'),
            color: 'success',
            time: this.$u.getReadingTime(this.$t('users.openingBalance.insert.notifications.creditor.success.message')),
            icon: 'icon-check',
            iconPack: 'feather'
          })

          this.$store.dispatch('removePageChanges')
          this.$store.dispatch('helper/changeOpeningBalance')
        }).catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: this.$u.getReadingTime(this.$t('alert.duplicateRequest.message')),
              color: 'warning'
            })
          } else {
            switch (error.response.status) {
            case 423:
              this.$vs.notify({
                title: this.$t('users.openingBalance.insert.notifications.locked.title'),
                text: this.$t('users.openingBalance.insert.notifications.locked.message'),
                color: 'danger',
                time: this.$u.getReadingTime(this.$t('users.openingBalance.insert.notifications.locked.message')),
                icon: 'icon-alert-circle',
                iconPack: 'feather'
              })
              break

            case 403:
              break

            default:
              this.$vs.notify({
                title: this.$t('users.openingBalance.insert.notifications.creditor.error.title'),
                text: this.$t('users.openingBalance.insert.notifications.creditor.error.message'),
                color: 'danger',
                time: this.$u.getReadingTime(this.$t('users.openingBalance.insert.notifications.creditor.error.message')),
                icon: 'icon-alert-circle',
                iconPack: 'feather'
              })
              break
            }
          }
        })
      }
    },
    getUsers () {
      if (!this.endedList) {

        getUsers(this.page).then(response => {
          const users = response.data.data
          users.forEach((user) => {
            this.suggests.name.push({
              label: `${user.name  } ${  user.family}`,
              value: {
                id: user.id,
                name: `${user.name  } ${  user.family}`,
                price: 0,
                flag: 'NEW'
              }
            })
          })

          this.endedList = response.data.pagination.total_pages === response.data.pagination.current_page
          this.usersList = this.suggests.name
        }).catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: this.$u.getReadingTime(this.$t('alert.duplicateRequest.message')),
              color: 'warning'
            })
          }
        })
      }
    },
    insertNewRow () {
      if (!this.$store.state.helper.openingBalanceLocked || this.data.length === 0) {
        this.data.push({
          id: 0,
          name: {
            value: '',
            suggestButtons: [
              {
                event: 'suggestPrompt:opened',
                icon: 'LIST'
              }
            ],
            disabled: true
          },
          price: {
            value: '',
            disabled: true
          },
          flag: 'NEW'
        })
      }
    },
    getSuggestList () {
      const users = JSON.parse(JSON.stringify({data: this.usersList}))
      const items = this.data.map((elm) => {
        return elm.id
      })
      const list = []

      users.data.forEach((user) => {
        if (items.indexOf(user.value.id) === -1) {
          list.push(user)
        }
      })

      this.suggests.name = JSON.parse(JSON.stringify(list))
    },
    handleNewUserInserted (user) {

      const newData = {}

      this.columnsLabel.forEach((column) => {
        if (user.hasOwnProperty(column.field)) newData[column.field] = user[column.field]
        else newData[column.field] = ''
      })
      newData.flag = 'New'
      newData.id = user.id
      newData.name = `${user.name  } ${  user.family}`
      this.data.unshift(newData)
      this.newSuggestUserPromptStatus = false
      this.suggestPromptStatus = false
      // this.$store.dispatch('setPageChanges')
      this.handleInsertNewRow()
    },
    handleSuggestSelected (list, index) {
      const rows = JSON.parse(JSON.stringify(this.data))
      if (rows[rows.length - 1].hasOwnProperty('id') && (rows[rows.length - 1].id === 0 || rows[rows.length - 1].id === '')) {
        rows.splice(rows.length - 1, 1)
      }
      this.data = []
      let i = 0
      list.forEach((data) => {
        if (!data.hasOwnProperty('show') || data.show) {
          i++
          const newData = {}

          this.columnsLabel.forEach((column) => {
            if (data.hasOwnProperty(column.field)) newData[column.field] = data[column.field]
            else newData[column.field] = ''
          })
          newData.flag = 'New'
          // console.log(newData)

          if (data.hasOwnProperty('id')) {
            newData.id = data.id
            const isExist = rows.map((elm) => {
              return elm.id
            }).indexOf(data.id) > -1
            if (isExist) {
              let text = this.$t('users.openingBalance.insert.notifications.isExists.message')
              if (newData.name) text = this.$t('users.openingBalance.insert.notifications.isExists.dynamicMessage', {name: newData.name})
              this.$vs.notify({
                title: this.$t('users.openingBalance.insert.notifications.isExists.title'),
                text,
                time: this.$u.getReadingTime(text),
                color: 'danger',
                icon: 'icon-alert-circle',
                iconPack: 'feather'
              })
            } else if (i === 1) rows[index] = newData
            else rows.push(newData)
          } else if (i === 1) rows[index] = newData
          else rows.push(newData)
        }
      })

      this.data = rows
      this.suggestPromptStatus = false
      // this.$store.dispatch('setPageChanges')
      this.handleInsertNewRow()
    },
    handleInsertNewRow () {
      this.getSuggestList()

      const checkName = this.data.map((elm) => {
        return elm.name === ''
      }).indexOf(true) > -1
      if (!checkName) {
        this.data.push({
          id: 0,
          name: {
            value: '',
            suggestButtons: [
              {
                event: 'suggestPrompt:opened',
                icon: 'LIST'
              }
            ],
            disabled: true
          },
          price: {
            value: '',
            disabled: true
          },
          flag: 'NEW'
        })
      }
    },
    handleDeleteRow (row) {
      if (row) {
        const index = this.data.map((elm) => {
          return elm.id
        }).indexOf(row.id)
        if (index > -1 && row.id !== 0) {
          // this.data.splice(index, 1)
          this.data[index].flag = 'UPDATED'
          this.data[index].price = 0
          /*setTimeout(() => {
            this.getSuggestList()
          }, 100)*/
          // this.$store.dispatch('setPageChanges')
        }
      }
    },
    handleReloadPrompt () {
      this.suggestPromptStatus = false
      setTimeout(() => {
        this.suggestPromptStatus = true
      }, 100)
    },
    checkOpeningBalanceStatus () {
      if (this.$store.state.helper.openingBalanceLocked) {
        const mapper = this.columnsLabel.map(elm => { return elm.field })

        const name_index = mapper.indexOf('name')
        this.columnsLabel[name_index].editable = false
        this.columnsLabel[name_index].suggest = false
        this.columnsLabel[name_index].suggestPrompt = false

        const price_index = mapper.indexOf('price')
        this.columnsLabel[price_index].editable = false

        const action_index = mapper.indexOf('delete')
        if (action_index > -1) {
          this.columnsLabel.splice(action_index, 1)
        }
      }
    },
    handleSendImportedFile () {
      if (this.importedDataFile.value) {
        const formData = new FormData()
        formData.append('imported_file', this.importedDataFile.value)

        if (this.type === 'debtor') {
          importDebtorUserOpeningInventories(formData).then(() => {
            this.$vs.notify({
              title: this.$t('alert.message.title'),
              text: this.$t('users.openingBalance.insert.notifications.importData.success'),
              color: 'success',
              icon: 'icon-check',
              iconPack: 'feather',
              time: this.$u.getReadingTime(this.$t('users.openingBalance.insert.notifications.importData.success'))
            })
            this.importDataPromptStatus = false
            this.$store.dispatch('removePageChanges')
            this.$store.dispatch('helper/changeOpeningBalance')
          })
            .catch(error => {

              if (axios.isCancel(error)) {
                this.$vs.notify({
                  title: this.$t('alert.duplicateRequest.title'),
                  text: this.$t('alert.duplicateRequest.message'),
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: this.$u.getReadingTime(this.$t('alert.duplicateRequest.message')),
                  color: 'warning'
                })
              } else {
                switch (error.response.status) {
                case 423:
                  this.$vs.notify({
                    title: this.$t('alert.error.title'),
                    text: this.$t('accountancy.openingBalance.validators.lock'),
                    color: 'danger',
                    time: this.$u.getReadingTime(this.$t('accountancy.openingBalance.validators.lock')),
                    icon: 'icon-alert-circle',
                    iconPack: 'feather'
                  })
                  break

                case 403:
                  break

                default:
                  this.$vs.notify({
                    title: this.$t('alert.error.title'),
                    text: this.$t('users.openingBalance.insert.notifications.importData.error'),
                    color: 'danger',
                    icon: 'icon-alert-circle',
                    iconPack: 'feather',
                    time: this.$u.getReadingTime(this.$t('users.openingBalance.insert.notifications.importData.error'))
                  })
                  break
                }
              }
            })
        } else {
          importCreditorUserOpeningInventories(formData).then(() => {
            this.$vs.notify({
              title: this.$t('alert.message.title'),
              text: this.$t('users.openingBalance.insert.notifications.importData.success'),
              color: 'success',
              icon: 'icon-check',
              iconPack: 'feather',
              time: this.$u.getReadingTime(this.$t('users.openingBalance.insert.notifications.importData.success'))
            })
            this.importDataPromptStatus = false
            this.$store.dispatch('removePageChanges')
            this.$store.dispatch('helper/changeOpeningBalance')
          })
            .catch(error => {

              if (axios.isCancel(error)) {
                this.$vs.notify({
                  title: this.$t('alert.duplicateRequest.title'),
                  text: this.$t('alert.duplicateRequest.message'),
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: this.$u.getReadingTime(this.$t('alert.duplicateRequest.message')),
                  color: 'warning'
                })
              } else {
                switch (error.response.status) {
                case 423:
                  this.$vs.notify({
                    title: this.$t('alert.error.title'),
                    text: this.$t('accountancy.openingBalance.validators.lock'),
                    color: 'danger',
                    time: this.$u.getReadingTime(this.$t('accountancy.openingBalance.validators.lock')),
                    icon: 'icon-alert-circle',
                    iconPack: 'feather'
                  })
                  break

                case 403:
                  break

                default:
                  this.$vs.notify({
                    title: this.$t('alert.error.title'),
                    text: this.$t('users.openingBalance.insert.notifications.importData.error'),
                    color: 'danger',
                    icon: 'icon-alert-circle',
                    iconPack: 'feather',
                    time: this.$u.getReadingTime(this.$t('users.openingBalance.insert.notifications.importData.error'))
                  })
                  break
                }
              }
            })
        }
      }
    }
  }
}
</script>

<style lang="scss">
.users-opening-balance-box {
  height: 100%;

  .draggable-dynamic-table {
    .new-suggest-modal-button {
      display: none;
    }

    .suggest-modal-button {
      right: 7px !important;
    }
  }
}
</style>
