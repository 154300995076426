<template>
  <div class="select-products-list">
    <draggable-dynamic-table ref="selectProductsListTable"
                             :data="data"
                             :columns="columnsLabel"
                             :options="options"
                             :in-modal="true"
                             @filter:remove="setFilter($event, true)"
                             @filter:set="setFilter($event)"
                             @sort:set="setSort($event)"
                             @column:select="selectedRows = $event, checkStatus($event)"
                             @product:edit="selectedProduct = $event, editProductPromptStatus = true"
                             @load:more="getProducts()"/>

    <!-- edit product prompt -->
    <vs-prompt
      class="medium-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="editProductPromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="$u.click('EditProductBTN')">
              <custom-icon icon="CHECK" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ selectedProduct ? $t('products.edit.dynamicTitle', {name: selectedProduct.name}) : $t('products.edit.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="editProductPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content p-2">
        <template>
          <edit-product :product-id="selectedProduct.productId" :in-modal="true" @edited="handleReloadTable"/>
        </template>
      </div>

    </vs-prompt>

    <vs-button id="selectProducts" class="useral-action-button" @click="$emit('selected', selectedRows)"/>
  </div>
</template>

<script>
import {
  getAllShreddedProducts,
  getInStockShreddedProducts,
  getShreddedProductsWithTurnover
} from '@/http/requests/products'
import {getCategories} from '@/http/requests/categories'
import {getStores} from '@/http/requests/stores'
import EditProduct from '@/views/admin/products/productsList/edit/editProduct'
import CustomIcon from '@/components/customIcon/customIcon'
import axios from 'axios'
import {getSaleInvoiceActiveStocks} from '@/http/requests/sales'
import {getPurchaseInvoiceActiveStocks} from '@/http/requests/purchases'

export default {
  name: 'selectProducts',
  components: {
    CustomIcon,
    EditProduct
  },
  props: {
    type: {
      Type: String,
      default: 'all',
      validator (value) {
        return ['all', 'in-stock', 'with-turnover', 'sale-refund', 'purchase-refund'].includes(value)
      }
    },
    storeroom: {
      Type: Boolean,
      default: false
    },
    multiSelect: {
      type: Boolean,
      default: () => { return true }
    },
    storeroomId: {
      type: [Number, String],
      default: 0,
      validator (value) {
        return parseInt(value || 0) >= 0
      }
    },
    invoiceId: {
      type: [Number, String],
      default: 0,
      validator (value) {
        return parseInt(value || 0) >= 0
      }
    },
    openingBalance: {
      type: [Number, String],
      default: 0
    },
    customer: {
      type: [Number, String],
      default: 0
    },
    supplier: {
      type: [Number, String],
      default: 0
    }
  },
  data () {
    return {
      selectedRows: [],
      selectedProduct: {},
      editProductPromptStatus: false,
      insertPromptStatus: false,
      loadingTimer: 0,
      checkStatusTimer: 0,
      selectedBanks: [],
      colors: {},
      options: {
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'edit',
          i18n: 'products.select.table.header.edit',
          width: '70px',
          minWidth: 70,
          /*sortable: true,*/
          actions: true,
          showAction: 'always',
          action: {
            color: 'warning',
            icon: 'EDIT',
            iconPack: 'useral',
            type: 'button'
          },
          event: 'product:edit'
        },
        {
          field: 'storeroom',
          i18n: 'products.select.table.header.storeroom',
          width: '150px',
          minWidth: 150,
          align: 'center',
          show: false,
          filter: true,
          filterType: 'checkbox',
          filterTypes: []
          // sortable: true,
          // locked: true,
        },
        {
          field: 'storeroomInventory',
          i18n: 'products.select.table.header.storeroomInventory',
          width: '140px',
          minWidth: 140,
          align: 'center',
          show: false
          // sortable: true,
          // locked: true,
        },
        {
          field: 'totalInventory',
          i18n: 'products.select.table.header.totalInventory',
          width: '120px',
          minWidth: 120,
          align: 'center',
          show: false
          // sortable: true,
          // locked: true,
        },
        {
          field: 'quantity',
          i18n: 'products.select.table.header.inventory',
          width: '70px',
          minWidth: 70,
          align: 'center'
          // sortable: true,
          // locked: true,
        },
        {
          field: 'salePrice',
          i18n: 'products.select.table.header.salePrice',
          hideCurrency: true,
          width: '150px',
          minWidth: 150,
          align: 'right',
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 3
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 4
            }
          ],
          sortable: true
        },
        {
          field: 'unitPrice',
          i18n: 'products.select.table.header.price',
          hideCurrency: true,
          width: '150px',
          minWidth: 150,
          align: 'right',
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 3
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 4
            }
          ],
          sortable: true
        },
        {
          field: 'purchasePriceWithVat',
          i18n: 'products.select.table.header.purchasePrice',
          hideCurrency: true,
          width: '150px',
          minWidth: 150,
          align: 'right',
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 3
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 4
            }
          ],
          sortable: true
        },
        {
          field: 'type',
          i18n: 'products.select.table.header.type',
          width: '100px',
          minWidth: 100,
          filter: true,
          align: 'center',
          filterType: 'select',
          filterTypes: [
            {
              label: this.$t('products.select.table.filters.type.all'),
              value: 0
            },
            {
              label: this.$t('products.select.table.filters.type.simple'),
              value: 1
            },
            {
              label: this.$t('products.select.table.filters.type.variant'),
              value: 2
            }
          ],
          sortable: true
          // locked: true,
        },
        {
          field: 'variant',
          i18n: 'products.select.table.header.variant',
          width: '150px',
          minWidth: 150,
          align: 'center',
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 0
            },
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            }
          ]
          // sortable: true
          // locked: true,
        },
        {
          field: 'name',
          i18n: 'products.select.table.header.name',
          width: 'calc(35%)',
          minWidth: 250,
          align: 'left',
          ellipsis: false,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 0
            }
          ],
          sortable: true
          // locked: true,
        },
        {
          field: 'category',
          i18n: 'products.select.table.header.category',
          width: '150px',
          minWidth: 150,
          align: 'center',
          filter: true,
          filterType: 'checkbox',
          filterTypes: []
          // sortable: true,
          // locked: true,
        },
        {
          field: 'barcode',
          i18n: 'products.select.table.header.barcode',
          width: '160px',
          minWidth: 160,
          align: 'center',
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 0
            }
          ],
          sortable: true
        },
        {
          field: 'code',
          i18n: 'products.select.table.header.code',
          width: '100px',
          minWidth: 100,
          align: 'center',
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 0
            }
          ],
          sortable: true
        },
        {
          field: 'image',
          i18n: 'products.select.table.header.image',
          // locked: true,
          width: '90px',
          minWidth: 90
        },
        {
          field: 'rowNumber',
          i18n: 'products.select.table.header.rowNumber',
          // sortable: true,
          // locked: true,
          fixed: true,
          align: 'center',
          width: '70px',
          minWidth: 70,
          footer: {}
        },
        {
          field: 'select',
          i18n: 'products.select.table.header.select',
          width: '50px',
          minWidth: 50,
          align: 'center',
          selectable: true,
          multiSelect: this.multiSelect,
          locked: true,
          fixed: true
        }
      ],
      data: [],
      filters: {},
      sorts: [!['sale-refund', 'purchase-refund'].includes(this.type) ? 'order[0]=updated_at,desc' : ''],
      page: 1,
      total_count: 1,
      endedList: false
    }
  },
  created () {
    if (this.storeroom || this.storeroomId > 0 || this.customer > 0 || this.supplier > 0) {
      this.columnsLabel.splice(this.columnsLabel.map(elm => {
        return elm.field
      }).indexOf('code'), 1)
      this.getStorerooms()
    } else {
      this.columnsLabel.splice(this.columnsLabel.map(elm => {
        return elm.field
      }).indexOf('storeroom'), 1)
      /*if (!['sale-refund', 'purchase-refund'].includes(this.type)) {
        this.columnsLabel.splice(this.columnsLabel.map(elm => {
          return elm.field
        }).indexOf('quantity'), 1)
      }*/
    }

    if (['purchase-refund', 'sale-refund'].includes(this.type)) {
      const purchaseIndex = this.columnsLabel.map(elm => {
        return elm.field
      }).indexOf('purchasePriceWithVat')
      if (purchaseIndex > -1) {
        this.columnsLabel[purchaseIndex].show = false
      }
      const salePriceIndex = this.columnsLabel.map(elm => {
        return elm.field
      }).indexOf('salePrice')
      if (purchaseIndex > -1) {
        this.columnsLabel[salePriceIndex].show = false
      }
    }

    if (this.type === 'purchase-refund') {
      const quantityIndex = this.columnsLabel.map(elm => {
        return elm.field
      }).indexOf('quantity')
      if (quantityIndex > -1) {
        this.columnsLabel[quantityIndex].i18n = 'products.select.table.header.quantity'
      }
      const storeroomInventoryIndex = this.columnsLabel.map(elm => {
        return elm.field
      }).indexOf('storeroomInventory')
      if (storeroomInventoryIndex > -1) {
        this.columnsLabel[storeroomInventoryIndex].show = true
      }
      const totalInventoryIndex = this.columnsLabel.map(elm => {
        return elm.field
      }).indexOf('totalInventory')
      if (totalInventoryIndex > -1) {
        this.columnsLabel[totalInventoryIndex].show = true
      }
    }

    /*if (this.openingBalance > 0) {
      this.filters = {openingInventory: `${  this.openingBalance}`}
    }

    if (this.storeroomId > 0 && this.storeroom) {
      this.filters = {storeroomId: `${  this.storeroomId}`}
    }*/

    if (this.customer > 0) {
      this.filters.customer = `${  this.customer}`
    } else if (this.supplier > 0) {
      this.filters.supplier = `${  this.supplier}`
    }

    this.getProducts()
    this.getCategories()
  },
  methods: {
    checkStatus (selected) {
      if (!this.multiSelect) {
        clearTimeout(this.checkStatusTimer)
        this.checkStatusTimer = setTimeout(() => {
          selected.forEach((select) => {
            if (select.show) this.$emit('selected', this.selectedRows)
          })
        }, 200)
      }
    },
    getProducts () {
      if (this.data.length < this.total_count || !this.total_count) {
        if (this.$refs.selectProductsListTable && this.data.length === 0) this.$refs.selectProductsListTable.loadMoreStatus = 'FirstLoad'
        else if (this.$refs.selectProductsListTable && this.data.length > 0) this.$refs.selectProductsListTable.loadMoreStatus = 'Loading'

        this.data = this.selectedRows
        switch (this.type) {
        case 'all':
          getAllShreddedProducts(this.page, this.filters, this.sorts, this.storeroom).then((response) => {
            this.setTableData(response)
          }).catch((error) => {
            console.log(error)
            if (this.$refs.selectProductsListTable && !axios.isCancel(error)) this.$refs.selectProductsListTable.loadMoreStatus = 'Danger'
          })
          break

        case 'in-stock':
          getInStockShreddedProducts(this.storeroomId, this.page, this.filters, this.sorts, this.storeroom).then((response) => {
            this.setTableData(response)
          }).catch((error) => {
            console.log(error)
            if (this.$refs.selectProductsListTable && !axios.isCancel(error)) this.$refs.selectProductsListTable.loadMoreStatus = 'Danger'
          })
          break

        case 'with-turnover':
          getShreddedProductsWithTurnover(this.page, this.filters, this.sorts, this.storeroom).then((response) => {
            this.setTableData(response)
          }).catch((error) => {
            console.log(error)
            if (this.$refs.selectProductsListTable && !axios.isCancel(error)) this.$refs.selectProductsListTable.loadMoreStatus = 'Danger'
          })
          break

        case 'sale-refund':
          getSaleInvoiceActiveStocks(this.invoiceId, this.page, this.filters, this.sorts, this.storeroom).then((response) => {
            this.setTableRefundData(response)
          }).catch((error) => {
            console.log(error)
            if (this.$refs.selectProductsListTable && !axios.isCancel(error)) this.$refs.selectProductsListTable.loadMoreStatus = 'Danger'
          })
          break

        case 'purchase-refund':
          getPurchaseInvoiceActiveStocks(this.invoiceId, this.page, {...this.filters, storeroom_id: this.storeroomId}, this.sorts, this.storeroom).then((response) => {
            this.setTableRefundData(response)
          }).catch((error) => {
            console.log(error)
            if (this.$refs.selectProductsListTable && !axios.isCancel(error)) this.$refs.selectProductsListTable.loadMoreStatus = 'Danger'
          })
          break
        }
      }
    },
    setTableData (response) {
      const products = response.data

      products.data.forEach(product => {
        const variables = product.variables.map(elm => {
          return `${elm.name.replaceAll('-', ' ')}: ${elm.value.replaceAll('-', '/')}`
        })
        const data = {
          id: this.storeroom ? parseInt(`${product.id}${product.storeroom.id}`) : product.id,
          variant_id: product.id,
          rowNumber: this.data.length + 1,
          image: {
            type: 'img',
            src: product.product.image ? this.$u.getAssetsUrl(product.product.image, 'h100,w100') : require('@/assets/images/no-image.png'),
            class: 'product-image'
          },
          productId: product.product.id,
          code: product.product.code,
          barcode: product.barcode,
          salePrice: {value: this.$u.parseFloat(product.sale_price) >= 0 ? product.sale_price : '', type: 'price'},
          unitPrice: {value: product.price, type: 'price'},
          purchasePrice: {
            value: this.$u.parseFloat(product.purchase_price) >= 0 ? product.purchase_price : '',
            type: 'price'
          },
          purchasePriceWithVat: {
            value: this.$u.parseFloat(product.purchase_price_with_vat) >= 0 ? product.purchase_price_with_vat : '',
            type: 'price'
          },
          fullName: `${product.product.title}${variables.length > 0 ? ' - ' : ''}${variables.join(', ')}`,
          name: `${product.product.title}`,
          variant: variables.length > 0 ? variables.join(', ') : '-',
          category: product.product.category.id === 4 ? this.$t('products.labels.withoutCategory') : product.product.category.name,
          type: product.product.type === 1 ? this.$t('products.product.type.simple') : product.product.type === 2 ? this.$t('products.product.type.variant') : this.$t('products.product.type.service'),
          styles: product.product.type === 1 || product.product.type === 3 ? '' : `background: ${this.colors.hasOwnProperty(product.product.code) ? this.colors[product.product.code] : this.getRandomColor(product.product.code)}`
        }

        if (this.storeroom || this.storeroomId > 0 || this.supplier > 0) {
          data.quantity = product.quantity
          data.storeroom = {
            id: product.storeroom ? product.storeroom.id : null,
            value: product.storeroom ? product.storeroom.name : '-'
          }
        }

        if (this.customer > 0) {
          data.quantity = product.quantity * -1
          data.storeroom = {
            id: product.storeroom ? product.storeroom.id : null,
            value: product.storeroom ? product.storeroom.name : '-'
          }
        }

        if (this.type === 'all') {
          data.quantity = product.total_sale
        }

        this.data.push(data)
      })

      if (products.pagination) {
        this.total_count = products.pagination.total
        this.page = products.pagination.current_page + 1

        if (response.data.pagination.current_page === 1) {
          const row_index = this.columnsLabel.map((e) => {
            return e.field
          }).indexOf('rowNumber')
          this.columnsLabel[row_index].footer.value = response.data.pagination.total
        }
      }

      if (this.$refs.selectProductsListTable) this.$refs.selectProductsListTable.loadMoreStatus = ''
    },
    setTableRefundData (response) {
      const products = response.data

      products.data.forEach(product => {
        const variables = product.variables.map(elm => {
          return `${elm.name.replaceAll('-', ' ')}: ${elm.value.replaceAll('-', '/')}`
        })
        const data = {
          id: this.storeroom ? parseInt(`${product.id}${product.storeroom.id}`) : product.id,
          variant_id: product.id,
          rowNumber: this.data.length + 1,
          image: {
            type: 'img',
            src: product.product.image ? this.$u.getAssetsUrl(product.product.image, 'h100,w100') : require('@/assets/images/no-image.png'),
            class: 'product-image'
          },
          productId: product.product.id,
          code: product.product.code,
          barcode: product.barcode,
          salePrice: {value: this.$u.parseFloat(product.discount) >= 0 ? this.$u.parseFloat(product.unit_price) - this.$u.parseFloat(product.discount) : product.unit_price, type: 'price'},
          unitPrice: {value: product.unit_price, type: 'price'},
          purchasePrice: {
            value: product.purchase_price && this.$u.parseFloat(product.purchase_price || '0') >= 0 ? product.purchase_price : '0',
            type: 'price'
          },
          purchasePriceWithVat: {
            value: this.$u.parseFloat(product.purchase_price_with_vat || '0') >= 0 ? product.purchase_price_with_vat : '',
            type: 'price'
          },
          quantity: product.quantity,
          storeroomInventory: product.storeroom_inventory || '',
          totalInventory: product.total_inventory || '',
          fullName: `${product.product.title}${variables.length > 0 ? ' - ' : ''}${variables.join(', ')}`,
          name: `${product.product.title}`,
          variant: variables.length > 0 ? variables.join(', ') : '-',
          category: product.product.category.id === 4 ? this.$t('products.labels.withoutCategory') : product.product.category.name,
          type: product.product.type === 1 ? this.$t('products.product.type.simple') : product.product.type === 2 ? this.$t('products.product.type.variant') : this.$t('products.product.type.service'),
          styles: product.product.type === 1 || product.product.type === 3 ? '' : `background: ${this.colors.hasOwnProperty(product.product.code) ? this.colors[product.product.code] : this.getRandomColor(product.product.code)}`
        }

        if (this.storeroom || this.storeroomId > 0 || this.supplier > 0) {
          data.storeroom = {
            id: product.storeroom ? product.storeroom.id : null,
            value: product.storeroom ? product.storeroom.name : '-'
          }
        }

        if (this.customer > 0) {
          data.quantity = product.quantity * -1
          data.storeroom = {
            id: product.storeroom ? product.storeroom.id : null,
            value: product.storeroom ? product.storeroom.name : '-'
          }
        }

        this.data.push(data)
      })

      if (products.pagination) {
        this.total_count = products.pagination.total
        this.page = products.pagination.current_page + 1

        if (response.data.pagination.current_page === 1) {
          const row_index = this.columnsLabel.map((e) => {
            return e.field
          }).indexOf('rowNumber')
          this.columnsLabel[row_index].footer.value = response.data.pagination.total
        }
      }

      if (this.$refs.selectProductsListTable) this.$refs.selectProductsListTable.loadMoreStatus = ''
    },
    getCategories () {
      getCategories(true).then(response => {
        const categories = []
        response.data.data.forEach((category) => {
          categories.push({
            id: category.id,
            label: category.name,
            value: category.id,
            show: false
          })
        })

        this.columnsLabel[this.columnsLabel.map(elm => {
          return elm.field
        }).indexOf('category')].filterTypes = categories
      })
    },
    getStorerooms () {
      getStores(true).then(response => {
        const stores = []
        response.data.data.forEach((store) => {
          stores.push({
            id: store.id,
            label: store.name,
            value: store.id,
            show: false
          })
        })

        this.columnsLabel[this.columnsLabel.map(elm => {
          return elm.field
        }).indexOf('storeroom')].filterTypes = stores
      })
    },
    getRandomColor (code) {
      const letters = 'BCDEF'.split('')
      let color = '#'
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * letters.length)]
      }
      color += '80'
      this.colors[code] = color
      return color
    },
    setFilter (filters) {
      const filters_list = {}
      Object.keys(filters).forEach((key) => {
        switch (key) {

        case 'variant':
          if (filters[key].search !== '') filters_list.variant = `${  filters[key].search  }`
          break

        case 'code':
          if (filters[key].search !== '') filters_list.code = `${  filters[key].search}`
          break

        case 'barcode':
          if (filters[key].search !== '') filters_list.barcode = `${  filters[key].search}`
          break

        case 'unitPrice':
          if (filters[key].search !== '') filters_list.price = `${  filters[key].search  },${  filters[key].type.id}`
          break

        case 'purchasePrice':
          if (filters[key].search !== '') filters_list.purchase_price = `${  filters[key].search  },${  filters[key].type.id}`
          break

        case 'purchasePriceWithVat':
          if (filters[key].search !== '') filters_list.purchase_price_with_vat = `${  filters[key].search  },${  filters[key].type.id}`
          break

        case 'salePrice':
          if (filters[key].search !== '') filters_list.sale_price = `${  filters[key].search  },${  filters[key].type.id}`
          break

        case 'category':
          if (filters[key].search.length) {
            const categories_id = filters[key].search.map(elm => {
              return elm.show ? elm.id : null
            }).filter(elm => {
              return elm !== null
            }).join(this.$u.globalData.filterSeperator)

            if (categories_id !== '') filters_list.category = `${  categories_id}`
          }
          break

        case 'name':
          if (filters[key].search !== '') filters_list.title = `${  filters[key].search}`
          break

        case 'type':
          if (filters[key].search.value > 0) filters_list.type = `${  filters[key].search.value === 1 ? 'simple' : 'variable'}`
          break
        }
      })

      /*if (this.openingBalance > 0) {
        filters_list.openingInventory = `${  this.openingBalance}`
      }

      if (this.storeroomId > 0 && this.storeroom) {
        filters_list.storeroomId = `${  this.storeroomId}`
      }*/

      if (this.customer > 0) {
        filters_list.customer = `${  this.customer}`
      } else if (this.supplier > 0) {
        filters_list.supplier = `${  this.supplier}`
      }

      // if (this.filters.join(',') !== filters_list.join(',')) {
      this.data = []
      this.page = 1
      this.endedList = false
      this.filters = filters_list

      this.$nextTick(() => {
        this.getProducts()
      })
      // }
    },
    setSort (sorts) {
      const sorts_list = []
      Object.keys(sorts).forEach((key) => {
        switch (key) {

        case 'rowNumber':
          sorts_list.push(`order[0]=id,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'code':
          sorts_list.push(`order[0]=code,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'barcode':
          sorts_list.push(`order[0]=barcode,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'category':
          sorts_list.push(`order[0]=category,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'name':
          sorts_list.push(`order[0]=title,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'type':
          sorts_list.push(`order[0]=type,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'variant':
          sorts_list.push(`order[0]=variant,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'unitPrice':
          sorts_list.push(`order[0]=price,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'salePrice':
          sorts_list.push(`order[0]=sale_price,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'purchasePriceWithVat':
          sorts_list.push(`order[0]=purchase_price_with_vat,${  sorts[key] ? 'desc' : 'asc'}`)
          break
        }
      })

      this.data = []
      this.page = 1
      this.endedList = false
      this.sorts = sorts_list
      this.$nextTick(() => {
        this.getProducts()
      })
    },
    handleReloadTable () {
      this.editProductPromptStatus = false
      this.data = []
      this.page = 1
      this.endedList = false
      this.$nextTick(() => {
        this.getProducts()
      })
    },
    handleChangeWarehouseStatus () {
      this.data = []
      this.page = 1
      this.endedList = false
      if (this.openingBalance > 0) {
        this.filters.openingInventory = `${  this.openingBalance}`
      }

      if (this.storeroomId > 0 && this.storeroom) {
        this.filters.storeroomId = `${  this.storeroomId}`
      }

      if (this.customer > 0) {
        this.filters.customer = `${  this.customer}`
      } else if (this.supplier > 0) {
        this.filters.supplier = `${  this.supplier}`
      }

      if (this.type === 'all') {
        const quantityIndex = this.columnsLabel.map(elm => {
          return elm.field
        }).indexOf('quantity')
        if (quantityIndex > -1) {
          this.columnsLabel[quantityIndex].show = false
        }
      } else {
        const quantityIndex = this.columnsLabel.map(elm => {
          return elm.field
        }).indexOf('quantity')
        if (quantityIndex > -1) {
          this.columnsLabel[quantityIndex].show = true
        }
      }

      this.$nextTick(() => {
        this.getProducts()
      })
    }
  },
  watch: {
    '$store.state.productsChanged': {
      handler (val) {
        if (val) {
          this.editProductPromptStatus = false
        }
      },
      deep: true
    },
    'storeroom': {
      handler () {
        this.handleChangeWarehouseStatus()
      }
    },
    'storeroomId': {
      handler () {
        this.handleChangeWarehouseStatus()
      }
    },
    'type': {
      handler () {
        this.handleChangeWarehouseStatus()
      }
    }
  }
}
</script>

<style lang="scss">
.select-products-list {
  height: 100%;


  .draggable-dynamic-table {

  }
}
</style>
